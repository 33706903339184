<template>
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
            <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="staff_id" class="col-form-label col-form-label-sm">Staff Name <span class="custom-required">*</span></label>
              <select v-model="staff_leave_application.staff_id" v-validate="'required'" id="staff_id" name="staff_id" class="form-control form-control-sm">
                <option selected  :value="''">Select a staff</option>
                <option v-for="(staff, index) in staffs" :key="index" :value="staff.id">{{ staff.name }} - {{ staff.phone_no }}</option>
              </select>
              <div class="invalid-feedback">Staff is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="staff_leave_type_id" class="col-form-label staff_leave_type_id">Staff Leave Type <span class="custom-required">*</span></label>
              <select v-model="staff_leave_application.staff_leave_type_id" v-validate="'required'" id="staff_type_id" name="staff_leave_type_id" class="form-control form-control-sm">
                <option selected  :value="''">Select a staff type</option>
                <option v-for="(staff_leave_type, index) in staff_leave_types" :key="index" :value="staff_leave_type.id">{{ staff_leave_type.name }}</option>
              </select>
              <div class="invalid-feedback">Staff leave type is required</div>
              <div class="card mt-2" v-if="staff_leave_applications.length > 0">
                <div class="card-body">
                 <span v-for="(staff_leave_application, index) in staff_leave_applications" :key="index">
                    Staff Leave Type: {{ staff_leave_application.staff_leave_type.name }} <br>
                    Total Duration: {{ staff_leave_application.staff_leave_type.duration }} <br>
                    Total Spend Duration: {{ staff_leave_application.total_durations }} <br>
                    Remaining Duration: {{ staff_leave_application.staff_leave_type.duration - staff_leave_application.total_durations }} <br>
                   <hr>
                </span>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="working_area" class="col-form-label col-form-label-sm">Staff Leave Date <span class="custom-required">*</span></label>
              <a-range-picker :disabled-date="disabledDate" @change="onDateRangeChange" />
              <div class="invalid-feedback">Staff leave Date is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6">
              <label for="staff_leave_image" class="col-form-label col-form-label-sm">Staff Leave Application Image <span class="custom-required">*</span></label>
              <input type="file" ref="staffLeaveApplicationImage" v-validate="'required'" id="staff_leave_application_image" name="staff_leave_application_image" @change="getStaffLeaveApplicationImage()" accept=".jpg,.jpeg,.png" class="form-control form-control-sm"/>
              <div class="invalid-feedback">Staff leave application image is required</div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-12">
          <a-button class="btn btn-sm btn-success float-left" :loading="submitLoading"  @click.prevent="save"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Create</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import moment from 'moment'

export default {
  name: 'create',
  data() {
    return {
      staff_leave_types: [],
      staffs: [],
      submitLoading: false,
      staff_leave_application: {
        staff_id: '',
        staff_leave_type_id: '',
        leave_date_range: '',
      },
      staff_leave_application_image: '',
      validation_errors: {},
      show: false,
      annual_leave_dates: [],
    }
  },
  computed: {
    staff_leave_applications () {
      const staffId = this.staff_leave_application.staff_id
      if (this.staff_leave_application.staff_id) {
        const staff = this.staffs.find(staff =>
          staff.id === staffId,
        )
        if (staff.staff_leave_applications.length > 0) {
          return staff.staff_leave_applications
        }
        return []
      }
      return []
    },
  },
  mounted() {
    this.getCodes()
  },
  methods: {
    disabledDate(current) {
      return (
        moment(current).day() === 5 ||
        this.annual_leave_dates.find(date => date === moment(current).format('YYYY-MM-DD'))
      )
    },
    onDateRangeChange(date, dateString) {
      this.staff_leave_application.leave_date_range = dateString
      console.log(dateString)
    },
    getCodes() {
      apiClient.get('/api/staff/codes')
        .then(response => {
          this.staffs = response.data.staffs
          this.staff_leave_types = response.data.staff_leave_types
          this.annual_leave_dates = response.data.annual_leave_dates
        })
        .catch(error => {
          console.log(error)
        })
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.submitLoading = true
          const formData = new FormData()
          formData.append('staff_id', this.staff_leave_application.staff_id)
          formData.append('staff_leave_type_id', this.staff_leave_application.staff_leave_type_id)
          formData.append('leave_date_range', this.staff_leave_application.leave_date_range)
          formData.append('staff_leave_application_image', this.staff_leave_application_image)
          apiClient.post('/api/hub/staff/leave-application/store', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } })
            .then(response => {
              this.submitLoading = false
              if (!response.data.error) {
                this.$notification.success({
                  message: response.data.message,
                })
                this.$router.push({ name: 'hubStaffApplicationList' })
              } else {
                this.$notification.error({
                  message: response.data.message,
                })
              }
            })
            .catch(error => {
              this.submitLoading = false
              this.validation_errors = error.response.data.errors
              this.show = true
              this.hide()
            })
        }
      })
    },
    getStaffLeaveApplicationImage() {
      this.staff_leave_application_image = this.$refs.staffLeaveApplicationImage.files[0]
      if (this.staff_leave_application_image.size > 3072000) {
        this.$notification.error({
          message: 'File size not greater then 3 MB',
        })
        this.staff_leave_application_image = ''
      }
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
    resetForm() {
      //
    },
  },
}
</script>

<style scoped>
.error-border-color input{
  border: 1px solid #dc3545 !important;
}
.error-border-color{
  border: 1px solid #dc3545 !important;
}
.error{
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.borderColor{
  border: 1px solid red !important;
  border-radius:4px !important;
}
</style>
